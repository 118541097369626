import { LifeCycles, pathToActiveWhen, registerApplication } from 'single-spa';

import {
  DockConfiguration,
  getSingleSpaMicrofrontend,
  SingleSpaProps,
  getSDK,
} from '@fnz-dock/core';

import { cleanDomElement, getDomElement } from './mount-element';

type MicrofrontendConfiguration = DockConfiguration['microfrontends'][number];
type ReadyFn = (
  configuration: MicrofrontendConfiguration,
  navigatorRenderDependant?: boolean,
) => boolean;

export const processMicrofrontends = async (
  microfrontends: DockConfiguration['microfrontends'],
  layoutReady: ReadyFn,
) => {
  await Promise.allSettled(
    microfrontends.map((configuration) => {
      return processMicrofrontend(configuration, layoutReady);
    }),
  );
};

const processMicrofrontend = async (
  microfrontendConfiguration: MicrofrontendConfiguration,
  layoutReady: ReadyFn,
) => {
  await setupMicrofrontend(microfrontendConfiguration);
  await registerMicrofrontend(microfrontendConfiguration, layoutReady);
};

const setupMicrofrontend = async (
  microfrontendConfiguration: MicrofrontendConfiguration,
) => {
  const module = await getSingleSpaMicrofrontend(microfrontendConfiguration.id);
  const { setup } = await module.getDockLifecycles();
  await setup(microfrontendConfiguration);
};

const verifyMicrofrontend = (
  singleSpaLifecycles: LifeCycles<SingleSpaProps>,
) => {
  if (
    !Array.isArray(singleSpaLifecycles.unmount) ||
    singleSpaLifecycles.unmount.length < 2
  ) {
    console.warn(
      'Most likely props.cleanup call is missing. It is important to call it on unmount',
    );
  }
};

const registerMicrofrontend = async (
  microfrontendConfiguration: MicrofrontendConfiguration,
  layoutReady: ReadyFn,
) => {
  const module = await getSingleSpaMicrofrontend(microfrontendConfiguration.id);
  // application
  if (microfrontendConfiguration.type === 'application') {
    const root = microfrontendConfiguration.path === '/';
    const activeWhen = pathToActiveWhen(microfrontendConfiguration.path, root);
    registerApplication<SingleSpaProps>({
      name: microfrontendConfiguration.id,
      app: async () => {
        const lifecycles = await module.getSingleSpaLifecycles();
        verifyMicrofrontend(lifecycles);
        return lifecycles;
      },
      activeWhen: (location) => {
        return layoutReady(microfrontendConfiguration) && activeWhen(location);
      },
      customProps: {
        cleanup() {
          return cleanDomElement(microfrontendConfiguration);
        },
        sdk: () => getSDK(microfrontendConfiguration),
        configuration: {
          ...microfrontendConfiguration,
        },
        get domElement() {
          return getDomElement(microfrontendConfiguration);
        },
      },
    });
  }
  // widget
  if (microfrontendConfiguration.type === 'widget') {
    registerApplication({
      name: microfrontendConfiguration.id,
      app: async () => {
        const lifecycles = await module.getSingleSpaLifecycles();
        verifyMicrofrontend(lifecycles);
        return lifecycles;
      },
      activeWhen: () => layoutReady(microfrontendConfiguration, true),
      customProps: {
        cleanup() {
          return cleanDomElement(microfrontendConfiguration);
        },
        sdk: () => getSDK(microfrontendConfiguration),
        configuration: microfrontendConfiguration,
        get domElement() {
          return getDomElement(microfrontendConfiguration);
        },
      },
    });
  }
};
