import {
  addErrorHandler,
  checkActivityFunctions,
  getAppStatus,
  navigateToUrl,
  SingleSpaCustomEventDetail,
  start,
  triggerAppChange,
} from 'single-spa';

import {
  bootstrap,
  MicrofrontendConfiguration,
  JSONObject,
} from '@fnz-dock/core';

import { updateBreadcrumbs } from './utils/breadcrumbs';
import { isMountedMFEMatchedWithLocation } from './utils/mfe-matched-location';
import { processMicrofrontends } from './utils/register';

export const init = async (
  configuration: JSONObject,
  extend: JSONObject = {},
) => {
  const dockConfiguration = await bootstrap({ configuration }, extend);
  let navigatorMounted = false;
  let navigatorRendered = false;
  const isLayoutReady = (
    configuration: MicrofrontendConfiguration,
    navigatorRenderDependant = false,
  ) => {
    if (configuration.id === '@fnz-dock/navigator') {
      return true;
    }
    if (navigatorRenderDependant) {
      return navigatorRendered;
    }
    return navigatorMounted;
  };

  await processMicrofrontends(dockConfiguration.microfrontends, isLayoutReady);
  // Once the navigator is mounted, we will trigger a change to mount product application
  window.addEventListener('single-spa:first-mount', () => {
    navigatorMounted = true;
    setTimeout(() => {
      navigatorRendered = true;
      triggerAppChange();
    }, 200);
  });
  window.addEventListener(
    'single-spa:before-mount-routing-event',
    (evt: CustomEvent<SingleSpaCustomEventDetail>) => {
      updateBreadcrumbs(evt, dockConfiguration);
    },
  );
  addErrorHandler((err) => {
    console.log(err);
    console.log(err.appOrParcelName);
    console.log(getAppStatus(err.appOrParcelName));
    navigateToUrl('/home');
  });
  window.addEventListener('single-spa:routing-event', () => {
    const activeApps = checkActivityFunctions(location);

    // ignore main page
    if (
      location.pathname !== '/' &&
      !isMountedMFEMatchedWithLocation(
        activeApps,
        dockConfiguration.microfrontends,
      ) &&
      !dockConfiguration.legacySSR
    ) {
      navigateToUrl('/error/404');
    }
  });
  start({ urlRerouteOnly: true });
};
